import { defineStore } from 'pinia';

export const useClientStore = defineStore('ClientStore', {
    state: () => ({
        room: '',
        initialSpendings: [{ id: 0 }] as Money[],
        adjustedSpendings: [] as Money[],
        incomes: [{ id: 0 }] as Money[],
        saving: { id: 0 } as Money,
        saveAmount: 0,
        oneYearSaving: 0,
        oneYearSpending: 0,
        oneYearInterests: 0,
        twoYearSaving: 0,
        twoYearSpending: 0,
        debt: 0,
        totalDebtPayment: 0,
        downpaymentTime: 0,
        phone: {} as Phone,
        payment: {} as Payment,
        giftPlacement: '',
        saveInterest: 3.5
    }),
    getters: {
        getInitialSpendingAmount: state => {
            return state.initialSpendings.reduce(
                (acc, val: any) => acc + parseInt(val.amount || 0),
                0
            );
        },
        getAdjustedSpendingAmount: state => {
            return state.adjustedSpendings.reduce(
                (acc, val: any) => acc + parseInt(val.amount || 0),
                0
            );
        },
        getBaseIncome: state => {
            return state.incomes.reduce((tot, cur) => tot + (cur.amount || 0), 0);
        },
        getBaseSpending(state): number {
            if (state.adjustedSpendings.length) {
                return this.getAdjustedSpendingAmount;
            }
            return this.getInitialSpendingAmount;
        },
        getDownpaymentCost: state => {
            // =B1*(C2)*(((1+C2)^B3)/((1 + C2)^B3-1))
            const price =
                state.phone.price * 0.015 * (Math.pow(1.015, 24) / (Math.pow(1.015, 24) - 1));
            return Math.ceil(price / 10) * 10 - 1;
        },
        oneMonthIncome(state): number {
            let tmp = this.getBaseIncome - this.getBaseSpending;
            if (state.payment.value === 'avbetaling') {
                tmp -= this.getDownpaymentCost;
            }
            return tmp;
        }
    },
    actions: {
        init(code: string): void {
            this.$state = JSON.parse(localStorage.getItem(`state-${code}`) || '[]');
        },
        fillDefaultInitialSpending(): void {
            this.initialSpendings = [
                { id: 0, amount: 1000 },
                { id: 2, amount: 860 },
                { id: 4, amount: 300 },
                { id: 11, amount: 1000 },
                { id: 5, amount: 250 },
                { id: 17, amount: 800 }
            ];
        },
        fillDefaultIncomes(): void {
            this.incomes = [
                { id: 1, amount: 3700 },
                { id: 0, amount: 1000 }
            ];
        },
        fillDefaultSaving(): void {
            this.saving = { id: 1, amount: 30000 };
        },
        async setRandomPhone(): Promise<void> {
            const { data } = await useAsyncData('phone-data', () =>
                queryContent('nb/client/page-8').findOne()
            );
            const num = Math.floor(Math.random() * 3);
            const type = ['iphone', 'android'][Math.floor(Math.random() * 2)];
            this.phone = data.value?.phones[type][num];
        },
        async setRandomPayment(): Promise<void> {
            const { data } = await useAsyncData('payment-data', () =>
                queryContent('nb/client/page-9').findOne()
            );
            const type = ['0', '2'][Math.floor(Math.random() * 2)];
            this.payment = data.value?.payments[type];
        }
    }
});
